import { usePreferences } from "@/composables/usePreferences";

export default defineNuxtPlugin(() => {
  const { isAuthenticated } = useSanctumAuth();

  const { clearPreferences } = usePreferences();

  onNuxtReady(async () => {
    // Clear local storage when the user logs out
    watch(
      isAuthenticated,
      async (newValue) => {
        if (!newValue) {
          clearPreferences();
        }
      },
    );
  });
});
